@text-gray: #AAAAAA;
@white: #FFFFFF;
@black: #000000;
@off-black: #1D1D1D;
@dark-gray: #222222;
@light-gray: #EBEBEB;
@line-gray: #7A7A7A;
@market-main: #BB261A;
@market-hover: #215583;

.juicer-feed a:hover {
  color: #b2b2b2;
}
.juicer-feed .j-meta a:hover {
  color: #000000;
}
.j-twitter-poster:hover {
  color: #b2b2b2;
}

:root {
  --market-main: @market-main;
  --market-hover: #BB261A;
  --mobile-nav-level2-bg: #CDCDCD;
  --mobile-nav-level2-fg: white;
  --mobile-nav-level3-bg: white;
  --mobile-nav-level3-fg: black;
}

@import '_base.less';